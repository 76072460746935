<template>
  <div class="user-withdrawal-list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '没有更多了': ''"
        @load="getWithdrawalList"
      >
        <div v-if="list.length > 0 || firstLoading" class="list">
          <div v-for="item in list" :key="item.id" class="item" @click="goDetail(item.id)">
            <div class="item-box">
              <div class="icon">
                <img v-if="item.bankIcon" :src="item.bankIcon" class="img">
                <img v-else :src="icon1" class="img">
              </div>
              <div class="info">
                <div class="title">{{ item.bankCardName }}({{ item.bankCardNo }})</div>
                <div class="des">{{ item.createdTime | parseTime }}</div>
              </div>
              <div class="status">
                <div class="des"><span v-if="item.status==10 || item.status">-</span>{{ item.amount | changeUnit }}</div>
                <div v-if="item.status == 1 || item.status == 2" class="title ing">
                  提现中
                </div>
                <div
                  v-else-if="item.status == 10 || item.status == 11"
                  class="title fail"
                >
                  提现失败
                </div>
                <div v-else class="title success">提现成功</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="emprty">
          <DefaultPage />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import icon1 from '@/assets/withdrawal/txjl_icon_yhk@2x.png'
import DefaultPage from '@/components/DefaultPage'
import { listWithdrawal } from '@/api/user'
export default {
  filters: {
    // 将单位转化为元
    changeUnit(val) {
      if (!val) return 0.0
      return (val / 100).toFixed(2)
    }
  },
  components: { DefaultPage },
  data() {
    return {
      list: [],
      params: { limit: 10, page: 1, createTimeSort: 2 },
      count: 0,
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: true,
      icon1
    }
  },
  methods: {
    goDetail(id) {
      this.$router.push({ name: 'userWithdrawalDetail', params: { id }})
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.params.page = 1
      this.getWithdrawalList()
    },
    async getWithdrawalList() {
      const res = await listWithdrawal(this.params)
      if (this.refreshing) {
        this.list = []
        this.page = 1
        this.refreshing = false
      }
      const list = res.datas && res.datas.data || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        this.list.push(item)
      }
      this.loading = false
      const count = res.datas && res.datas.count
      this.count = count
      if (this.list.length >= count * 1) {
        this.finished = true
      }
      this.params.page += 1
      this.firstLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.user-withdrawal-list {
  .list {
    padding: 0 0;
    .item {
      padding: 0 .426667rem;
      background: #fff;
      .item-box {
        display: flex;
        padding: .533333rem 0;
        border-bottom: 1px solid #F3F3F5;
      }
      .icon {
        .img {
          width: 0.986667rem;
          height: 0.986667rem;
        }
      }
      .info{
        flex: 1;
        padding-left: .3rem;
        .title{
          font-size: .426667rem;
          color: #323233;
        }
        .des{
          padding-top: .266667rem;
          font-size: .373333rem;
          color:#CCCCCC;
        }
      }
      .status{
        text-align: right;
        .title{
          font-size: .426667rem;
          text-align: center;
          color:#323233;
        }
        .success{
          color: #18C47C;
        }
        .fail{
          color: #F86767;
        }
        .ing {
          color: #2697FF;
        }
        .des{
          padding-top: .1rem;
          font-size: .4rem;
          font-weight: bold;
          color:#333333;
          .unit {
            font-size: .26rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
